import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import axios from "axios";
import { REGISTER_API } from "../../api";
import { LOGIN_API } from "../../api";

function Authentication(props) {
  const { register, handleSubmit, errors, reset } = useForm();
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(
        props.isRegister ? REGISTER_API : LOGIN_API,
        data
      );
      console.log("👉 Returned data:", response.data);
      history.push("/dashboard");
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
        setErrorMsg(error.response.data.msg);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
    reset();
    setTimeout(() => {
      setErrorMsg("");
    }, 4000);
    setLoading(false);
  };

  return (
    <div>
      <div className="box">
        {props.isRegister ? (
          <div>
            <h2 className="heading">Create Account</h2>
            <p className="subheading">
              Please sign up for your personal account to get started.
            </p>
          </div>
        ) : (
            <div>
              {" "}
              <h2 className="heading">Sign in to SwitchSimply</h2>
              <p className="subheading">
                Please use your registered Google Account
            </p>
            </div>
          )}
        <form>
          <button type="submit" style={{ backgroundColor: "#f96d80" }}>
            <i className="fab fa-google" /> Continue with Google
          </button>
        </form>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="inBetweenPara">Or continue with Email</p>
          {props.isRegister && (
            <input
              type="text"
              placeholder="Name"
              name="name"
              ref={register({ required: "This field is required" })}
            />
          )}
          {errors.name && <p>{errors.name.message}</p>}
          <input
            type="email"
            placeholder="Email"
            name="email"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}
          <input
            type="password"
            placeholder="Password"
            name="password"
            ref={register({
              required: "This field is required",
              minLength: {
                value: 8,
                message: "Too Short, min 8 characters required",
              },
            })}
          />
          {errors.password && <p>{errors.password.message}</p>}
          {props.isRegister && (
            <input
              type="text"
              placeholder="Referral"
              name="referral"
              ref={register({ required: "This field is required" })}
            />
          )}
          {errors.referral && <p>{errors.referral.message}</p>}
          <p style={{ color: "red", fontWeight: 900 }}>{errorMsg}</p>
          {isLoading && (
            <Loader type="Bars" color="#fc5185" height={40} width={40} />
          )}
          {props.isRegister ? (
            <div>
              <button type="submit">Get Started</button>
              <p>
                Already have an Account?{" "}
                <Link to="/login" className="sign-link">
                  Sign In
                </Link>
              </p>
            </div>
          ) : (
              <div>
                <button type="submit">Login</button>
                <p>
                  Don't have an Account?{" "}
                  <Link to="/register" className="sign-link">
                    Sign Up
                </Link>
                </p>
              </div>
            )}
        </form>
      </div>
    </div>
  );
}

export default Authentication;
