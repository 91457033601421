import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Privacy from "./Privacy";
import Dashboard from "./Dashboard";
import Authentication from "./Authentication";

export default function Pages() {
  return (
    <div>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route
          path="/register"
          exact
          component={() => <Authentication isRegister={true} />}
        />
        <Route
          path="/login"
          exact
          component={() => <Authentication isRegister={false} />}
        />
      </Switch>
    </div>
  );
}
