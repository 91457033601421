import React from "react";

function About() {
  return (
    <div>
      <h2>About Us</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Porttitor massa id
        neque aliquam. Ac turpis egestas sed tempus urna et. Et malesuada fames
        ac turpis egestas sed. Pharetra massa massa ultricies mi quis hendrerit
        dolor magna. Eu non diam phasellus vestibulum lorem sed risus ultricies
        tristique. Volutpat ac tincidunt vitae semper. Hendrerit gravida rutrum
        quisque non tellus orci ac auctor. Adipiscing bibendum est ultricies
        integer quis auctor. Semper risus in hendrerit gravida rutrum quisque
        non tellus. Commodo odio aenean sed adipiscing diam donec adipiscing
        tristique risus. Commodo quis imperdiet massa tincidunt nunc pulvinar
        sapien. Egestas sed tempus urna et pharetra pharetra massa massa. Cum
        sociis natoque penatibus et magnis dis parturient montes. Accumsan lacus
        vel facilisis volutpat est velit egestas. Imperdiet proin fermentum leo
        vel orci porta non pulvinar neque. Diam sollicitudin tempor id eu nisl
        nunc mi ipsum faucibus. Tempor orci dapibus ultrices in.
      </p>
    </div>
  );
}

export default About;
