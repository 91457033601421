import React from "react";
import Switch from "../../components/room/Switch";
import WaterLevel from "../../components/room/WaterLevel";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import TvIcon from "@material-ui/icons/Tv";
import AlbumIcon from "@material-ui/icons/Album";
import KitchenIcon from "@material-ui/icons/Kitchen";

export default function Dashboard() {
  const date = new Date();
  const currentTime = date.getHours();
  let greeting;

  if (currentTime < 12) {
    greeting = "Good Morning";
  } else if (currentTime < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Night";
  }

  return (
    <div>
      <h2>{greeting} Test, Welcome Home!</h2>
      <WaterLevel />
      <Switch name="Room Light" icon={<WbIncandescentIcon />} />
      <Switch name="Air Conditioner" icon={<AcUnitIcon />} />
      <Switch name="Television" icon={<TvIcon />} />
      <Switch name="Music System" icon={<AlbumIcon />} />
      <Switch name="Refrigerator" icon={<KitchenIcon />} />
    </div>
  );
}
