import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { BoxLoading } from "react-loadingg";

import Navbar from "./components/Layout/Navbar";
import Footer from "./components/Layout/Footer";

import Pages from "./pages";

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return isLoading ? (
    <BoxLoading size={"large"} color="#206a5d" />
  ) : (
    <BrowserRouter>
      <Navbar />
      <div className="container">
        <Pages />
      </div>
      <Footer />
    </BrowserRouter>
  );
}
export default App;
