import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <nav>
      <div className="navbar">
        <NavLink to="/" className="brand">
          SwitchSimply
        </NavLink>
        <NavLink to="/contact" className="nav-right">
          Contact Us
        </NavLink>
        <NavLink to="/about" className="nav-right">
          About
        </NavLink>
        <NavLink to="/" className="nav-right">
          Home
        </NavLink>
      </div>
    </nav>
  );
}

export default Navbar;
