import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

import { WATERLEVEL_API } from "../../api";

export default function WaterLevel() {
  const [resData, setData] = useState([]);

  try {
    useEffect(() => {
      async function getData() {
        const response = await axios.get(WATERLEVEL_API);

        setData(response.data.feeds);
      }
      getData();
    }, []);
  } catch (error) {
    if (error.response) {
      console.log(error.response.status);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
  }
  function xAxisTickFormatter(created_at) {
    return moment(created_at).format("YYYY/MM/DD HH:mm");
  }

  return (
    <div>
      <h3>Water Level Indicator</h3>
      <ResponsiveContainer height={400}>
        <AreaChart
          data={resData}
          margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#38a6f2" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#92D0F4" stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="created_at"
            tickFormatter={xAxisTickFormatter}
            style={{
              fontSize: "0.8rem",
              fontFamily: "Times New Roman",
            }}
          >
            <Label value="Time" position="insideBottom" dy={15} />
          </XAxis>
          <YAxis
            type="number"
            domain={[0, 100]}
            label={{ value: "Water Level", angle: -90, position: "insideLeft" }}
            style={{
              fontSize: "0.8rem",
              fontFamily: "Times New Roman",
            }}
          />
          <Tooltip
            labelFormatter={xAxisTickFormatter}
            content={({ active, payload, label }) => {
              if (active && payload) {
                return (
                  <div>
                    <h4>{payload[0].payload.field1}%</h4>
                    <h5>{xAxisTickFormatter(label)}</h5>
                  </div>
                );
              }
            }}
          />
          <CartesianGrid vertical={false} stroke="#ebf3f0" />
          <Area
            type="monotone"
            dataKey="field1"
            stroke="#0C87DD"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
