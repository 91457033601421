import React, { useState } from "react";

function Switch(props) {
  const [status, setStatus] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    if (status === false) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }

  return (
    <div className="widget">
      <h3>{props.name}</h3>
      <div className="applianceIcon">{props.icon}</div>
      <div className="homeButton">
        <button
          style={{ backgroundColor: status ? "green" : "red" }}
          onClick={handleClick}
          type="checkbox"
        >
          {status ? "ON" : "OFF"}
        </button>
      </div>
    </div>
  );
}

export default Switch;
