import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import homepageImage from "../../images/homepage.jpg";

export default function Home() {
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <div className="home-heading">
            <h1>How we work?</h1>
            <h3>Pushing up the boundaries of what's possible</h3>
            <Link to="/register" className="homeButton">
              <button type="button">Sign Up</button>
            </Link>
            <Link to="/login" className="homeButton">
              <button type="button">Log In</button>
            </Link>
          </div>
        </Grid>
        <Grid item xs={8}>
          <img src={homepageImage} alt="homepage" />
        </Grid>
      </Grid>
    </div>
  );
}
