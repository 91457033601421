import React from "react";
//import Typography from "@material-ui/core";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <p className="footer-text">@ Copyright SwitchSimply {currentYear}</p>
    </footer>
  );
}

export default Footer;
